(function($) {
	
	$.fn.fillText = function(options) {

		var $this = $(this),
			globalSettings = $.extend({
				minFontSize: Number.NEGATIVE_INFINITY,
				maxFontSize: Number.POSITIVE_INFINITY,
				multiplier: 1,
				equal: false,
				callback: null,
                responsive: {}
            }, options),
            defaultSettings = $.extend({}, globalSettings),
            currentSettings = globalSettings,
            equalSize = Number.POSITIVE_INFINITY,

        _init = function() {
            var windowWidth = window.innerWidth;
            for (var breakpoint in globalSettings.responsive) {
                if (windowWidth <= breakpoint) {
                    currentSettings = $.extend(defaultSettings, globalSettings.responsive[breakpoint]);
                }
            }

        	$this.each(_handle);
        	if (currentSettings.equal) {
	        	$this.css('font-size', equalSize);
	        }
	        if (globalSettings.callback != null) {
	        	globalSettings.callback();
	        }
        },
        _handle = function() {
        	var $current = $(this),
        		result = _calculate($current);
        	if (!currentSettings.equal) {
	        	$current.css('font-size', result.size);
	        }
        },
        _calculate = function($el) {
        	var width = $el.width(),
        		currentFontSize = parseInt($el.css('font-size')),
        		textWidth = $el.textWidth();

        	var fontSize = (currentFontSize * (width / textWidth)) * currentSettings.multiplier;

        	fontSize = Math.min(fontSize, currentSettings.maxFontSize);
        	fontSize = Math.max(fontSize, currentSettings.minFontSize);

        	fontSize = Math.floor(fontSize);

        	//If all elements to be equal size
			if (currentSettings.equal) {
				equalSize = Math.min(equalSize, fontSize);
				fontSize = equalSize;
			}

        	return {
        		width: width,
        		size: fontSize,
        		textWidth: textWidth
        	};
        };

        var previousWidth = window.innerWidth;
        $(window).on('resize.fillText orientationchange.fillText', function() {
        	var currentWidth = window.innerWidth;
        	if (previousWidth == currentWidth) return false;
        	previousWidth = currentWidth;
        	_init();
        });

        delete defaultSettings.responsive;

        _init();

        return this;

	};

	$.fn.textWidth = function(){
		var html_org = $(this).html();
		var html_calc = '<span>' + html_org + '</span>';
		$(this).html(html_calc);
		var width = $(this).find('span:first').width();
		$(this).html(html_org);
		return width;
	};

})(jQuery);